var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "公共模版", name: "Public template" } },
            [
              _vm.activeName === "Public template"
                ? _c("head-layout", {
                    attrs: {
                      "head-title": "作业票模板",
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-remove": _vm.handleDelete,
                      "head-add": _vm.headAddPublic,
                    },
                  })
                : _vm._e(),
              _c("grid-head-layout", {
                ref: "gridHeadLayoutPublic",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                  switchClick: _vm.gridHeadSearch,
                },
              }),
              _vm.activeName === "Public template"
                ? _c("grid-layout", {
                    ref: "gridLayOutPublic",
                    attrs: {
                      "table-options": _vm.option,
                      "table-data": _vm.publicTableData,
                      "table-loading": _vm.publicLoading,
                      "data-total": _vm.publicPage.total,
                      "grid-row-btn": _vm.gridRowBtn,
                      page: _vm.publicPage,
                    },
                    on: {
                      "page-current-change": _vm.onLoadPublic,
                      "page-size-change": _vm.onLoadPublic,
                      "gird-handle-select-click": _vm.selectionChange,
                      "grid-row-detail-click": _vm.rowView,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.isEnable === 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.diabledTicket(row)
                                        },
                                      },
                                    },
                                    [_vm._v("停用")]
                                  )
                                : _vm._e(),
                              row.isEnable === 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              row.isEnable === 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDel(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      352592200
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "自定义模版", name: "Custom template" } },
            [
              _vm.activeName === "Custom template"
                ? _c("head-layout", {
                    attrs: {
                      "head-title": "作业票模板",
                      "head-btn-options": _vm.headBtnOptions,
                    },
                    on: {
                      "head-remove": _vm.handleDelete,
                      "head-add": _vm.headAdd,
                    },
                  })
                : _vm._e(),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                  switchClick: _vm.gridHeadSearch,
                },
              }),
              _vm.activeName === "Custom template"
                ? _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "table-options": _vm.option,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.loading,
                      "data-total": _vm.page.total,
                      "grid-row-btn": _vm.gridRowBtn,
                      page: _vm.page,
                    },
                    on: {
                      "page-current-change": _vm.onLoad,
                      "page-size-change": _vm.onLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                      "grid-row-detail-click": _vm.rowView,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.isEnable === 1
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.diabledTicket(row)
                                        },
                                      },
                                    },
                                    [_vm._v("停用")]
                                  )
                                : _vm._e(),
                              row.isEnable === 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              row.isEnable === 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDel(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      352592200
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }